.hide{display:none;}

// LAYOUT
%resetAllLists {
  ul{margin:0;padding:0;list-style:none;}
  li{margin:0;padding:0;}
}

%bigTitle{font-size:1.5rem;font-weight:900;text-transform:uppercase;color:$color-darker;
  a{color:$color-white;text-decoration:none;
    &:hover{color:$color-secondary;}
  }
  @include breakpoint(medium){font-size:2.8rem;}
}

%bigTitleBox{@extend %bigTitle;padding:0 .3em;color:$color-white;background:rgba(0,0,0,.3);
  @include breakpoint(medium){background:rgba(0,0,0,.1)}
}


%simpleBtn{display:inline-block;padding:.5rem 1.5rem;background:$color-btn;font-size:1rem;color:$color-white;font-weight:900;text-transform:uppercase;text-decoration:none;
  &:hover{color:$color-secondary;text-decoration:none;}
  @include breakpoint(medium){padding:1.25rem 4.25rem;font-size:1.125rem;}
}

%shortUnderline{content:'';display:block;width:110px;margin:1rem auto;border:1px solid $color-grey3;}
%shortUpperline{@extend %shortUnderline;margin:0 auto 1rem;}

%mainHeader{color:$color-darker;font-size:1.5rem;font-weight:bold;
  @include breakpoint(large){font-size:2.8rem;}
}

// CLEARFIX PLACEHOLDER
%clearfix {
  &:after{content:"";display:table;clear:both;}
}

// display flex
@mixin flex($justifyContent: space-between){
  display:flex;flex-flow:row wrap;justify-content:$justifyContent/*space-around is an alternative*/;
}
