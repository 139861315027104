/* BASKET SUMMARY */
#basketSummary{padding:10px 20px;}
#basketSummary .title{margin-top:10px;padding:5px 13px 10px;font-size:1.667em;font-weight:bold;color:#fff;background:#326e9c url('img/menu4_bg.png') no-repeat left bottom;}
#basketSummary .title a{color:#fff;background:none;text-decoration:none;}
#basketSummary .title a:hover{text-decoration:underline;}
#basketSummary ol{margin:6px 0;padding:0 5px;list-style:none;font-weight:bold;}
#basketSummary li{padding:5px 3px 7px;border-bottom:1px solid #e0e0e0;}
#basketSummary .lL{border:0;}
#basketSummary li span.name{display:block;font-weight:bold;font-size:0.9em;}
#basketSummary li a{color:#2e6a97;}
#basketSummary li a:hover{color:$color-btn;}
#basketSummary li div{padding:5px 0 0;font-weight:normal;font-size:0.9em;}
#basketSummary li .priceQuantity{color:$color-btn;background:inherit;}
#basketSummary li strong{padding:0 2px 0 5px;font-weight:bold;}
#basketSummary li em{color:#666;background:inherit;font-style:normal;}
#basketSummary .summary{margin:0 5px;padding:5px;border-top:2px solid #e0e0e0;color:$color-btn;text-align:center;font-weight:bold;}
#basketSummary .summary em{background:inherit;color:#666;font-style:normal;}
#basketSummary .summary strong{padding:0 3px;font-weight:bold;}
#basketSummary .summary span{color:#666;font-weight:bold;}

/* PROMOTED PRODUCTS */
#promoted{margin-bottom:10px;padding:0 20px;}
#promoted .title{margin-top:10px;padding:5px 0px 10px 7px;font-size:1.667em;font-weight:bold;color:#fff;background:#326e9c url('img/menu4_bg.png') no-repeat left bottom;}
#promoted ul{list-style:none;padding-bottom:10px;text-align:center;}
#promoted li{padding:13px 0;border-bottom:1px solid #e0e0e0;}
#promoted li img{padding:1px;background:#c0bfbf;border:4px solid #eee;-webkit-box-shadow:0 0 10px rgba(48,47,47,.4);box-shadow:0 0 10px rgba(48,47,47,.4);}
#promoted li img:hover{border:4px solid #c9cdce;}
#promoted li .name{font-size:1.1em;padding:5px 0;font-weight:bold;}
#promoted li a{text-decoration:none;}
#promoted li a:hover{background:inherit;color:#666;text-decoration:underline;}
#promoted li .price, #promoted li .noPrice{padding:2px 0 0;background:inherit;color:$color-btn;}
#promoted li .price strong{padding:0 2px 0 5px;font-weight:bold;}
#promoted li .price em{padding:0;background:inherit;color:#666;font-style:normal;}
#promoted li .price span{color:#666;font-weight:bold;padding-left:5px;}

/* FILES LIST STYLES */
#filesList{width:100%;float:left;margin:15px 0 5px;list-style:none;vertical-align:middle;border-left:2px solid #e0e0e0;}
#filesList li{width:100%;float:left;clear:left;margin:5px 0 5px 10px;padding-left:10px;}
#filesList li a{color:$color-btn;background:inherit;}
#filesList li a:hover{color:#666;background:inherit;}
#filesList img{margin:0 8px 0 0;vertical-align:middle;}
#filesList em{padding-left:2px;}


/* SOCIAL BUTTONS */
#plusOne{position:absolute;top:20px;right:20px;}
#socialButtons{clear:both;float:left;width:100%;margin:10px 0;padding:6px 0 4px;text-align:left;list-style:none;border-bottom:1px solid #dadada;-webkit-box-shadow:0 8px 5px -5px rgba(0,0,0,.15);box-shadow:0 8px 5px -5px rgba(0,0,0,.15);}
#socialButtons .title{display:block;float:left;margin:0 0 0 15px;padding:2px 0 0;}
#socialButtons li{float:left;margin:0 10px 0;}
#socialButtons li.social2 img{border:1px solid #ccc;}
#socialButtons a img{vertical-align:middle;}

/* SITE MAP */
#siteMap{margin:5px 0;list-style:none;}
* html #siteMap{float:left;width:100%;}
#siteMap li{margin:0 0 13px;padding:8px 10px 10px;background:#fff;border-bottom:1px solid #dadada;-webkit-box-shadow:0 8px 5px -5px rgba(0,0,0,.15);box-shadow:0 8px 5px -5px rgba(0,0,0,.15);}
#siteMap li a{font-weight:bold;font-size:1.1em;color:$color-btn;background:none;}
#siteMap li a:hover{color:#2e6996;background:none;}
#siteMap ul{margin:0;padding:3px 0 0 10px;border:0;list-style:none;}
#siteMap ul li{margin:0;padding:4px 10px;border:0;-webkit-box-shadow:none;box-shadow:none;}
#siteMap ul li a{font-weight:bold;color:#2e6996;background:none;}
#siteMap ul.products li{border:0;background:url('img/menu3_sub.gif') no-repeat 3px 11px;-webkit-box-shadow:none;box-shadow:none;}
#siteMap ul.products li a{color:#333;font-weight:normal;text-decoration:none;}
#siteMap ul.products li{padding-left:15px;margin-left:5px;}
#siteMap li ul li a:hover, #siteMap ul.products li a:hover{color:$color-btn;background:none;text-decoration:underline;}
#siteMap li .price{display:inline;font-size:0.917em;color:#2e6996;padding-left:5px;}
#siteMap li strong{padding:0 5px 0 7px;background-color:inherit;color:#2e6996;}
#siteMap li span{background-color:inherit;color:#2e6996;}

/* COMMENTS */
#commentForm{clear:both;width:100%;margin:10px auto 0;}
#commentForm fieldset{padding:25px 20px;}
#commentForm dl, #commentForm dt, #commentForm dd{margin:0;padding:0;}
#commentForm dt{padding:0 0 7px;}
#commentForm dd{margin-bottom:18px;}
#commentForm dt.vote{color:#2e6a97;font-weight:bold;}
#commentForm .input, #commentForm textarea{width:99%;padding:3px;font-size:1em;}
#commentForm #captcha{width:9%;}
#commentForm .captcha em{font-style:normal;font-weight:bold;}
#commentForm .captcha ins{padding:0 3px;font-weight:bold;text-decoration:none;}
#commentForm legend{position:absolute;clip:rect(1px, 1px, 1px, 1px);}
#commentForm .save{text-align:center;}

#commentTitle{overflow:hidden;margin:18px 0 10px;padding-left:10px;background:inherit;color:#2e6896;font-size:2.2em;font-weight:normal;border-left:2px solid #e0e0e0;background:inherit;}
#comments{float:left;width:100%;margin:7px 0;list-style:none;}
#comments li{clear:left;float:left;width:96%;margin:10px 0;padding:13px 2%;border:1px solid #e0e0e0;-webkit-box-shadow:0 8px 5px -5px rgba(0,0,0,.15);box-shadow:0 8px 5px -5px rgba(0,0,0,.15);}
* html #comments li{width:100%;}
#comments div{float:left;width:25%;}
#comments span.name{display:block;font-size:1em;color:#2e6896;font-weight:bold;}
#comments span.date{display:block;font-size:1em;margin-top:3px;}
#comments p{font-weight:normal;font-size:1em;line-height:1.4em;width:75%;float:right;}

/* PRODUCTS RATING */
#productRating{margin:10px 0 5px;text-align:center;}
#productRating .results{margin:2px 0 0;font-size:0.9em;}
#productRating .results strong{padding:0 0 0 2px;}
.ratingDisplay{width:80px;height:16px;background: url('img/stars.gif') no-repeat 0 0;font-size:0em;margin:6px auto;}
#comments .ratingDisplay{width:80px;margin-bottom:0;opacity:0.5;}
.rating{float:left;width:80px;height:16px;margin:0;padding:0;list-style:none;position:relative;clear:both;background: url('img/stars.gif') no-repeat 0 0;}
.stars0{background-position:0 0}
.stars1{background-position:0 -96px}
.stars2{background-position:0 -112px}
.stars3{background-position:0 -128px}
.stars4{background-position:0 -144px}
.stars5{background-position:0 -160px}
#commentForm dd.vote{float:left;}
#commentForm dt.vote{float:left;margin-right:5px;}
* html #commentForm dd.vote{margin-bottom:0;}
#commentForm .commentContent{clear:both;}
#commentForm ul.rating li{cursor:pointer;float:left;text-indent:-999em;}
#commentForm ul.rating li a {position:absolute;left:0;top:0;width:16px;height:16px;text-decoration:none;z-index: 200;}
#commentForm ul.rating li.one a{left:0}
#commentForm ul.rating li.two a{left:16px;}
#commentForm ul.rating li.three a{left:32px;}
#commentForm ul.rating li.four a{left:48px;}
#commentForm ul.rating li.five a{left:64px;}
#commentForm ul.rating li a:hover{z-index:2;width:80px;height:16px;overflow:hidden;left:0;background: url('img/stars.gif') no-repeat 0 0}
#commentForm ul.rating li.one a:hover{background-position:0 -96px;}
#commentForm ul.rating li.two a:hover{background-position:0 -112px;}
#commentForm ul.rating li.three a:hover{background-position:0 -128px}
#commentForm ul.rating li.four a:hover{background-position:0 -144px}
#commentForm ul.rating li.five a:hover{background-position:0 -160px}

/* CONTACT FORM */
#contactPanel{clear:both;width:100%;margin:10px auto 0;}
#contactPanel fieldset{padding:25px 20px;}
#contactPanel fieldset legend{position:absolute;clip:rect(1px, 1px, 1px, 1px);}
#contactPanel dl, #contactPanel dt, #contactPanel dd{margin:0;padding:0;}
#contactPanel dt{padding:0 0 7px;}
#contactPanel dd{margin-bottom:18px;}
#contactPanel .input, #contactPanel textarea{width:99%;padding:3px;font-size:1em;}
#contactPanel #captcha{width:9%;}
#contactPanel .captcha em{font-style:normal;font-weight:bold;}
#contactPanel .captcha ins{padding:0 3px;font-weight:bold;text-decoration:none;}
#contactPanel .save{text-align:center;}

/* PAYMENTS */
#paymentDescription{margin:10px 30px 20px;padding:20px;text-align:center;border:1px solid #bebebe;font-weight:bold;}
#paymentOuter{margin:10px 0 0;}
#paymentChannels{padding-left:20px;}
#paymentChannels select, #paymentChannels .zagielInfo{display:none;}
#paymentChannels .zagielInfo label{display:inline;}
a.zagielInfo{margin:0 20px 0 0;}
.zagielInfo a{margin-left:5px;font-weight:normal;}

.message{clear:both;padding:50px 0;text-align:center;}
.message h2{background:inherit;color:$color-btn;font-weight:normal;font-size:2.4em;line-height:1.7em;}
.message h2 a{color:#2e6a97;background:inherit;}
.message h2 a:hover{color:#666;background:inherit;}

/* PLUGINS */


#order #ceneoAccept label{font-weight:normal;font-size:0.917em;}

.noticesAccepts{text-align:left;padding:0px;margin:0px;}
.noticeToAccept{padding-bottom:10px;}
.noticeToAccept span{margin-left:4px;}
#profile .noticeToAccept{padding-bottom:18px;}
#newsletter .noticeToAccept{padding:6px 0;}
#newsletter .noticeToAccept label{display:inline;}
#noticeLayer{display:none;padding:7px 0;background:#fff;font-weight:bold;border-bottom:1px solid #555;}
#noticeLayer .close{font-size:0.917em;color:#999;font-weight:normal;text-decoration:none;}
#noticeLayer .close:hover{color:#f00;}

#darkBg{display:none;position:absolute;top:0;left:0;width:100%;height:100%;background:#000;opacity:.5;filter:alpha(opacity=50);-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";z-index:999;}
#noticeLayer.darkNotice{position:fixed;top:100px;left:50%;z-index:1000;width:50%;margin-left:-25%;padding:30px 10%;text-align:center;background:#fff;border:5px solid #fff;border-radius:10px;-moz-border-radius:10px;-webkit-border-radius:10px;box-sizing:border-box;}
#noticeLayer.darkNotice .close{display:block;margin-top:20px;text-align:center;}
#prevNext{list-style:none;height:25px;font-size:1.1em;margin:0 5px;}
#prevNext a{font-weight:bold;}
#prevNext .prev{float:left;}
#prevNext .next{float:right;}

/* STOCK */
#page .stockLevel, #page .stockSold{margin:5px 0;font-size:0.917em;}
#page .stockSold{background:inherit;color:$color-btn;font-weight:bold;}
#page .stockLevel strong{margin:0 0 0 2px;}
#product .stockLevel, #product .stockSold{margin:8px 10px 4px;padding:5px 0 6px;font-weight:bold;font-size:0.917em;}
#product .stockSold{color:$color-btn;}
#product .stockLevel strong{margin:0 0 0 4px;background:inherit;color:$color-btn;}
#basket #productsOverStock h5{margin-top:10px;padding:8px 0;font-size:1em;font-weight:bold;border-top:1px solid #e0e0e0;}
#basket #productsOverStock strong{display:block;padding:2px 10px;background:inherit;color:$color-btn;}
#basket #productsOverStock div{margin:0 0 10px;padding:5px 0 10px;font-size:1em;font-weight:normal;border-bottom:1px solid #e0e0e0;}
#product .recommended, #products .recommended{padding-left:5px;}

/* RELATED PRODUCTS */
#productsRelated{clear:both;float:left;width:100%;border-top:2px solid #e0e0e0;padding-top:10px;}
#productsRelated h3{font-size:1.7em;font-weight:normal;color:$color-btn;}
#productsRelated ul{float:left;width:100%;margin:10px 0;padding:15px 0 10px;list-style:none;border-bottom:2px solid #e0e0e0;}
#productsRelated li{float:left;width:32%;margin-left:2%;padding:5px 0 15px;text-align:center;vertical-align:top;}
#productsRelated li.column1{clear:both;margin-left:0;}
#productsRelated img{margin-bottom:5px;padding:1px;background:#c0bfbf;border:4px solid #eee;-webkit-box-shadow:0 0 10px rgba(48,47,47,.4);box-shadow:0 0 10px rgba(48,47,47,.4);}
#productsRelated img:hover{border:4px solid #c9cdce;}
#productsRelated a{display:block;padding:0 5px;font-weight:bold;text-decoration:none;}
#productsRelated a:hover{text-decoration:underline;}
#productsRelated .price, #productsRelated .noPrice{padding:6px 0 0;color:#2e6a97;}
#productsRelated .price strong{padding:0 2px 0 5px;font-weight:bold;}
#productsRelated .price em{padding:0;background:inherit;font-style:normal;}
#productsRelated .price span{font-weight:bold;padding-left:5px;}
@media print{
  #productsRelated .price em, #productsRelated .price span, #productsRelated .price strong{font-weight:normal;}
  #productsRelated{float:none;width:17.2cm;padding:0;margin:0;}
}

@media screen and (max-width:650px){
  #productsRelated .price em{display:none;}
}
@media screen and (max-width:360px){
  #productsRelated li{width:98%;}
  #productsRelated .price em{display:initial;}
}

/* TAGS */
#tags{margin:10px 0;padding:0;list-style:none;}
#tags li{display:inline-block;padding:0 5px 0 0;}
#tags li a{font-weight:bold;}


/* CROSS SELL */
#crossSell{clear:both;margin:10px 0;padding:8px 10px 10px;background:#fff url('img/body.png');border-bottom:1px solid #dadada;-webkit-box-shadow:0 8px 5px -5px rgba(0,0,0,.15);box-shadow:0 8px 5px -5px rgba(0,0,0,.15);}
#crossSell h3{color:$color-btn;font-size:1.7em;font-weight:normal;}
#crossSell ul{margin:10px 10px 10px 20px;padding:8px 0 0;list-style:none;}
#crossSell li{padding:0 0 7px;background:url('img/menu3_sub.gif') no-repeat left 5px;padding-left:12px;}
#crossSell li a{color:#666;font-weight:bold;text-decoration:none;}
#crossSell li a:hover{background-color:inherit;color:$color-btn;text-decoration:underline;}
#crossSell .price, #crossSell .name{display:inline;}
#crossSell .price{color:#2e6a97;padding:0 5px 0 15px;}
#crossSell .price em{display:none;}
#crossSell .price span{padding-left:5px;} 
.infoDiscount{color:#666;background:inherit;font-size: 0.9em;padding-bottom:5px;text-align:center;}
