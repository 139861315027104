// BREAKPOINTS
@mixin breakpoint($point) {
  /* WIDTH */
  @if $point == xxlarge { // 1920px
    @media (min-width: 120em) { @content ; }
  }
  @else if $point == xlarge { // 1280px
    @media (min-width: 80em) { @content ; }
  }
  @else if $point == large { // 1024px
    @media (min-width: 64em) { @content ; }
  }
  @else if $point == medium { // 800px
    @media (min-width: 50em) { @content ; }
  }
  @else if $point == smallonly { // below medium - MAX
    @media (max-width:49.9em) { @content ; }
  }
  @else if $point == small { // 480px
    @media (min-width: 30em) { @content ; }
  }
  @else if $point == belowmax { // < 1300 px - MAX
    @media (max-width: 81em) { @content ; }
  }

  /* HEIGHT */
  @else if $point == largeH { // 1024px
    @media (min-height: 64em) { @content ; }
  }
  @else if $point == mediumH { // 600px
    @media (min-height: 37em) { @content ; }
  }
  @else if $point == smallHOnly { // below mediumH - MAX
    @media (max-height: 36.9em) { @content ; }
  }
  @else if $point == smallH { // 400px
    @media (min-height: 25em) { @content ; }
  }
}
// for more options you can use: https://github.com/sass-mq/sass-mq

// PX DENSITY
@mixin hiDensity($ddpx: 2dppx, $dpi: 192dpi, $ratio: 2) {
  @media /* High px density (mostly mobile) - so you can magnify something */
  only screen and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (        min-device-pixel-ratio: $ratio),
  only screen and (min-resolution: $dpi),
  only screen and (min-resolution: $ddpx){
    @content ;
  }
}
