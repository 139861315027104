@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Black.ttf')  format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Bold.ttf')  format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Regular.ttf')  format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato-Light.ttf')  format('truetype');
  font-weight: 300;
}
