/* PRODUCTS LIST */
.products{clear:both;margin:10px 0;list-style:none;text-align:center;
  &__list{display:flex;justify-content:space-between;flex-flow:row wrap;list-style:none;
    &:before{order:99;}
    &:before, &:after{content:'';flex:0 1 313px;}
  }
  &__product{display:flex;flex-direction:column;justify-content:space-between;
            max-width:313px;margin-bottom:1.5rem;border:1px solid transparent;
    > * {margin-bottom:1.25rem;}
    .photo img{max-width:100%;}
    h2{font-size:1.125em;}
    h2 a{text-decoration:none;font-weight:normal;}
    h2 a:hover{}
    .price, .noPrice{text-decoration:none;font-size:1.125rem;font-weight:bold;
      em{font-style:normal;font-weight:normal;}
      strong{padding:0 4px;}
      &:before{@extend %shortUpperline;}
    }

    h3{display:none;}
    div.description{display:none;}
    .basket{display:none;}
    &:hover{border:1px solid $color-secondary;
      .price:before{border-color:$color-secondary;}
    }
  }

  /*.slick-slider{
    .products__product{padding:0 5px;
      .photo img{max-width:100%;}
    }
  }*/

  /* PAGES */
  // pages count grid areas
  .pages .viewAll{grid-area:viewall;
    a{display:block;padding:1rem;background: $color-btn;color:$color-white;text-decoration: none;text-transform: uppercase;font-weight: bold;
      &:hover{color:$color-secondary;}
    }
  }
  .pages ul{grid-area:pages-list;}

  .pages{display:grid;
          justify-content:space-between;
          grid-template-columns: 1fr;
          grid-template-areas: "pages-list"
                               "viewall";
          @include breakpoint(xlarge){
            grid-template-columns: 300px 1fr 300px;
            grid-template-areas: ". pages-list viewall";
          }
    &.pagesBefore{display:none;}
    & > *{padding:.5rem 0;}
  }
 
  &__filter{display:flex;justify-content:flex-end;align-items:center;flex-flow:column;margin:.5rem 0 1rem;
    @include breakpoint(medium){flex-flow:row;}
    & > *{margin-top:1rem;}
  }

  &__producer{margin-right:2rem;
    select{padding:2px 6px;}
  }

  .sort{border:0;background:none;text-align:left;}
  .sort ul{display:inline;list-style:none;}
  .sort li{display:inline;padding:0 6px;font-weight:bold;border-right:1px solid #666;}
  .sort li:last-child{border:none;padding-right:0;}
  .sort li a{font-weight:normal;}
}

// exceptions for products on HOME page
.page-home .products__product{margin-bottom:0;}
