// Quick.Cart.Ext default stylesheet
/*! This file is generated by Gulp Sass script, do not edit it by hand. */

// VARIABLES

$font-main: 'Lato', sans-serif;

$color-white: #fff;
$color-black: #000;

$color-primary: $color-black;
$color-secondary: #F4AC45;
$color-darker: #605757;
$color-lighther: #DAD6D6;
$color-dark: #343434;
$color-grey-light: #f7f7ff;
$color-grey: #707070;
$color-grey2: #BEBEBE;
$color-grey3:#AEAEAE;
$color-grey4: #ECECEC;
$color-btn: #3C304E;

$font-color-link: $color-primary;

$bg-color-white: $color-white;
$bg-color-dark: $color-black;

$border-color-white: $color-white;

$error-color: $color-secondary;
$size-base: 1em;

$icon-play: 'img/play.svg';

@import "0_plugins/reset_simple";

@import "fonts";

@import "base_elements";

@import "common_mixins_rwd";
@import "common_mixins_placeholders";

@import "style-qc";

@import "modules__products-list";
@import "modules__products-details";

@import "modules__order-basket";
@import "modules__order-form";
@import "modules__order-summary-print";

@import "modules_drop-menu";
@import "modules_sliders";
@import "modules_boxes";
@import "modules_forms";
@import "modules_wcag";
@import "modules_page";
@import "modules_images";
@import "modules_quickbox";
@import "modules_subpages";
@import "modules_pages-grid";
@import "modules_search";
@import "modules_old-price";
@import "modules_simple-notice";
@import "modules_backtotop";

@import "layout_0-main";
@import "0_plugins/hamburgers/hamburgers";
@import "layout_1-head";
//@import "layout_2-column";
@import "layout_3-content";
@import "layout_4-foot";

@import "layout-pages_home";

@import "0_plugins/slick";
@import "0_plugins/slick-theme";

@import "layout_print";

